/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .comeos-header {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
}
.altai-theme-content #bodyView .comeos-header img {
  margin-bottom: 0;
}
@media screen and (max-width: 1280px) {
  .altai-theme-content #bodyView .comeos-header .flex-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }
  .altai-theme-content #bodyView .comeos-header .flex-right {
    display: flex;
    flex: 4;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1281px) {
  .altai-theme-content #bodyView .comeos-header .flex-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }
  .altai-theme-content #bodyView .comeos-header .flex-right {
    display: flex;
    flex: 2;
    justify-content: flex-end;
  }
}
.altai-theme-content #bodyView .comeos-header .header-top {
  display: flex;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-logo {
  padding-top: 25px;
  width: 130px;
}
.altai-theme-content #bodyView .comeos-header .header-top ul:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content),
.altai-theme-content #bodyView .comeos-header .header-top ol:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content) {
  line-height: 3em;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu {
  text-align: right;
  color: white;
  display: flex;
  justify-content: flex-end;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul {
  z-index: 10;
  margin-bottom: 0 !important;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul li {
  float: left;
  margin-right: 5px;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul li.userinfo {
  color: #444;
  cursor: pointer;
  padding: 22px 0;
  font-weight: 600;
  font-size: 0.8em;
  letter-spacing: 0;
  height: 50px;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul li.userinfo a {
  color: #444;
  font-weight: 600;
}
@media screen and (max-width: 1150px) {
  .altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul li.userinfoname {
    display: none;
  }
  .altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul li.userinfoicon {
    display: inline-block;
  }
}
@media screen and (min-width: 1151px) {
  .altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul li.userinfoname {
    display: inline-block;
  }
  .altai-theme-content #bodyView .comeos-header .header-top .header-top-menu ul li.userinfoicon {
    display: none;
  }
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu .comeos-menu-zone .nav-bar ul.menu li {
  padding-left: 0px;
  padding-right: 0px;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu .altai-language-bar.forusers .language-selector-user {
  display: inline-block;
  padding: 0 1.2em;
  padding-right: 6px;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu .altai-language-bar.forusers .language-selector-user:focus {
  outline: none !important;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu .header-circle {
  width: 220px;
  height: 220px;
  background-color: #000000;
  border-radius: 50%;
  right: 70px;
  z-index: 5;
  text-align: center;
  padding-top: 154px;
  margin-top: -153px;
  cursor: pointer;
  font-weight: 600;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu .mycomeos-link {
  margin-left: 50px;
  font-weight: bold;
  margin-right: 100px;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu .logout {
  margin-left: 5px;
  padding-top: 10px;
}
.altai-theme-content #bodyView .comeos-header .header-top .header-top-menu.dynamic {
  margin-right: 50px;
}
.altai-theme-content #bodyView .comeos-header-small {
  background-color: #EDEDED;
  height: 50px;
}
.altai-theme-content #bodyView .comeos-header-small .comeos-menu-zone {
  height: 50px;
}
.altai-theme-content #bodyView .comeos-header-small .comeos-menu-zone .nav-bar {
  height: 50px;
  max-height: 50px;
  line-height: 50px;
}
.altai-theme-content #bodyView .comeos-header-small .comeos-menu-zone .nav-bar .nav-module {
  height: 50px;
}
.altai-theme-content #bodyView .comeos-header-small .comeos-menu-zone .nav-bar .nav-module ul.menu {
  height: 50px;
}
.altai-theme-content #bodyView .comeos-header-small .comeos-menu-zone .nav-bar .nav-module ul.menu li {
  height: 50px;
}
.altai-theme-content #bodyView .comeos-header-small .header-top .header-top-logo {
  padding-top: 0;
}
.altai-theme-content #bodyView .comeos-header-small .header-top .header-top-logo img {
  height: 40px;
  padding-top: 10px;
}
.altai-theme-content #bodyView .comeos-header-small .header-top .header-top-menu .header-circle {
  background-color: #000;
  color: white;
  padding-top: 182px;
  margin-top: -163px;
  font-weight: 600;
  font-size: 1em;
}
.altai-theme-content #bodyView .comeos-header-small .header-top .header-top-menu .mycomeos-link {
  margin-right: 110px;
}
.altai-theme-content #bodyView .comeos-header-small .header-top .home .header-top-menu .header-circle {
  margin-top: -173px;
}
.altai-theme-content #bodyView .comeos-header-home {
  height: 600px;
  background: #00B4E1;
}
.altai-theme-content #bodyView .comeos-header-home .header-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.altai-theme-content #bodyView .comeos-header-home .header-content .slidertext-container {
  width: 1024px;
}
.altai-theme-content #bodyView .comeos-header-home .header-content .slidertext-container .slidertext {
  color: white;
  font-size: 24px;
  width: 350px;
}
.altai-theme-content #bodyView .comeos-header-home .header-content .slidertext-container .slidertext .slidertext-title {
  font-weight: bold;
  font-size: 42px;
}
.altai-theme-content #bodyView .comeos-header-home .header-content .slidertext-container .slidertext .slidertext-content {
  font-size: 16px;
  line-height: 175%;
  margin-top: 25px;
}
.altai-theme-content #bodyView .comeos-header-home .header-content .slidertext-container .slidertext .slidertext-navigation {
  font-size: 12px;
  margin-top: 25px;
}
